import React from 'react'
import { useLocation,useNavigate } from "react-router-dom";
import { useParams } from 'react-router';
import { useState } from 'react';
import axios from 'axios';
import { environment } from './../enviroments/enviroment';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import Card from '@material-ui/core/Card';
import logo  from '../assets/images/jca.png';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.typography.button,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1),
  },
}));

const useStyles2 = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});
export default function Fvoto() {
  var sele = [];

  var countRespuestas = 0;
  var hack;
  var tiempo= false;
  const classes = useStyles();
  const classes2 = useStyles2();
  const [user, setUser] = React.useState(null);

  const [tipovoto, setTipoVoto] = React.useState(null);
  const [opcion, setOpcion] = React.useState(null);
  const [votacion, setVotacion] = React.useState(null);
  const [categoria, setCategoria] = React.useState(null);

  const [hacker, setHacker] = React.useState(null);
  const [eleccion, setEleccion] = React.useState(null);
  const [atrapado, setAtrapado] = React.useState(null);
  const [clave, setClave] = useState("");
  const [imagen, setImagen] = React.useState(null);
  const marcada = require('../assets/images/marcada.png');

const marcar = require('../assets/images/marcar.png');
  const navigate = useNavigate();
  const {state} = useLocation();


  

  const { id } = useParams();
   sele.length=0;
 

   






   React.useEffect(() => {

    const interval = setInterval(() => {

    axios.get(`${environment.api}votacion/${id}`).then((response) => {
        console.log('votacion',response);
      setVotacion(response.data);
     
    });
   

    axios.get(`${environment.api}votacion/votar/2/${id}`).then((response) => {
      console.log('ocpion',response);
      setOpcion(response.data);
     
    });

    axios.get(`${environment.api}categoria/ver/${id}`).then((response) => {
   
      setCategoria(response.data);
     
    });

}, 2000);
return () => clearInterval(interval);




   
  }, []);



  

 
 
  

  


  
  if (!votacion) return null;
  
 
  if (!categoria) return null;
  if (!opcion) return null;
 





  







 


 
  

  return (
    <div style={{
      textAlign: "center",
      
    }}>
        
<h1 class="titulo"><strong> {votacion.nombre} </strong> </h1>

<img style={{width: '200px'}}  src={logo} alt="" /> 
    <br/>
<br/>
<div style={{
      textAlign: "center",
      backgroundColor: "#33FF9F",
      margin:"5px",
      borderRadius:"15px",
    }} className={classes.root}>{`Opciones escogidas ${eleccion == null ? "0" : eleccion}  de un máximo de ${votacion.maximoOpciones} ` }</div>

<br/>
<strong><h2><strong>  {categoria.texto} </strong></h2></strong>

<Card style={{
     border: "3px solid #87CEEB",
      margin: "30px",
      borderRadius: "15px",
    }} className={classes.root}>

{opcion.map((item,i) => 
   <CardContent style={{
    cursor: "pointer"
    }}  >

<div style={{
     width: "100%",
     display: "flex",
     flexDirection: "row",
     justifyContent: "center",
     opacity: "unset",
    }} >

<div onClick = {() =>


{ 

  console.log(item.seleccionada[i]);

} }   

>



{ item.seleccionada ? (
            <img key={i}  
            src={marcada}
           
            />
          

          ) : (
      
            <img   key={i}  
            src={marcar}
           
            />    
                    )}

                    

</div>      
          <div style={{
     width: "100px",
     opacity: "unset",
     textAlign: "center",
    }}>
<strong> <h4  style={{
     color: "#393969",
     margin: "15px",
     textAlign: "center",
    }} 
    
    dangerouslySetInnerHTML={{ __html: item.texto }}
    ></h4></strong>

            </div>

</div>   
      </CardContent>
  )}
    
     
    </Card>

    <TextField     type="text"     id="clave" onChange={e => setClave(e.target.value)}   value={clave} inputProps={{ maxLength: 9 }}  placeholder="clave de votación" required  />
<br/>

<br/>

<Button style={{maxWidth: '400px', maxHeight: '400px', minWidth: '300px', minHeight: '30px',backgroundColor:'#8db600'}} variant="contained" color="secondary" disableElevation
               onClick={() => {
                
                  
              }}
              >
  Votar
</Button>

<br/>
<br/>
<Button style={{maxWidth: '400px', maxHeight: '400px', minWidth: '300px', minHeight: '30px',backgroundColor:'red'}} variant="contained" color="secondary"
               onClick={() => {
                

              }}
              >
  Anular
</Button>
    </div>

    
  )
}
