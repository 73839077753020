import React from 'react'
import { useLocation,useNavigate } from "react-router-dom";
import { useParams } from 'react-router';
import { useState } from 'react';
import axios from 'axios';
import { environment } from './../enviroments/enviroment';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import logo  from '../assets/images/jca.png';
import Swal from 'sweetalert2';
import Divider from '@mui/material/Divider';
const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.typography.button,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1),
  },
}));

const useStyles2 = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});
export default function Votar() {
  var sele = [];

  var countRespuestas = 0;
  var hack;
  var tiempo= false;
  const classes = useStyles();
  const classes2 = useStyles2();
  const [user, setUser] = React.useState(null);

  const [tipovoto, setTipoVoto] = React.useState(null);
  const [opcion, setOpcion] = React.useState(null);
  const [votacion, setVotacion] = React.useState(null);
  const [categoria, setCategoria] = React.useState(null);

  const [hacker, setHacker] = React.useState(null);
  const [eleccion, setEleccion] = React.useState(null);
  const [atrapado, setAtrapado] = React.useState(null);
  const [clave, setClave] = useState("");
  const [imagen, setImagen] = React.useState(null);
  const marcada = require('../assets/images/marcada.png');

const marcar = require('../assets/images/marcar.png');
  const navigate = useNavigate();
  const {state} = useLocation();
  const { rut, votacionId } = state; 

  console.log('vengo con ',rut);

   sele.length=0;
 

   


function hackeado(rut,votacionId){
  axios.get(`${environment.api}votacion/comprobarVotoHombre/${rut}/${votacionId}`).then((response) => {
 
    
    if(response.data.length > 0){
      navigate('/*****---',{ state: { id: rut, color: 'green' } });
       }
      
   
  });
}

function pollData(rut,votacionId) {
  hack = setInterval(() => {
    if(tiempo === false)

    hackeado(rut,Number(votacionId));


    tiempo=true;

  },100)
}




   React.useEffect(() => {

    axios.get(`${environment.api}votacion/comprobarVotoHombre/${rut}/${votacionId}`).then((response) => {
   
      
      if(response.data.length > 0){
        navigate('/votaciones/'+rut);
         }
  
  
       
      });

    axios.get(`${environment.api}votacion/${votacionId}`).then((response) => {
   
      setVotacion(response.data);
     
    });
    axios.get(`${environment.api}user/usuarioRut/${rut}`).then((response) => {
   
      setUser(response.data);
     
    });

    axios.get(`${environment.api}votacion/votar/${rut}/${votacionId}`).then((response) => {
      console.log('ocpion',response);
      setOpcion(response.data);
     
    });

    axios.get(`${environment.api}categoria/ver/${votacionId}`).then((response) => {
   
      setCategoria(response.data);
     
    });

    axios.get(`${environment.api}votacion/comprobarVotoHombre/${rut}/${votacionId}`).then((response) => {
   
     
      if(response.data.length > 0){
       navigate('/*****---',{ state: { id: rut, color: 'green' } });
        }
     
    });


   pollData(rut,votacionId);

   
  }, []);




  

  if (!votacion) return null;
  if (!user) return null;
  if (!categoria) return null;
  if (!opcion) return null;
 
  

  


  
  function escogidas() {
    //the thing  you wanna do
   countRespuestas = 0;

   for(let i3 =0; i3 < opcion.length; i3++ ){

       if(opcion[i3].seleccionada === true){
        countRespuestas++;
       }
   }
   setEleccion(countRespuestas);
   console.log('respuestas',countRespuestas);
    
  }
  
  function votarNulo(){


    if(clave==''){
      Swal.fire('Estimado/a', 'La clave no puede ir vacia!', 'info');
    }else{

      if(clave===user.secret){

        Swal.fire({
          title: 'Atención, Votación Nula',
          text: '¿Esta seguro de emitir el Voto?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Votar',
          confirmButtonColor: 'green',
          cancelButtonColor: 'red',
          cancelButtonText: 'Cancelar',
        }).then((result) => {
          if (result.value) {
            setTipoVoto('NULO');
            votoNulo(rut,Number(votacionId));


            navigate('/*****---',{ state: { id: rut, color: 'green' } });
       


          } else if (result.dismiss === Swal.DismissReason.cancel) {
            Swal.fire('Cancelado', 'Vuelva a seleccionar', 'info');
          }
        });

  }
  else{
    Swal.fire('Estimado/a', 'La clave que esta ingresando no su clave de votación!', 'info');
  }

  }


  }


  function votoNulo(rut,votacionId) {

    axios.post(`${environment.api}votacion/votoNulo/${rut}/${votacionId}`).then((response) => {
      
    console.log(response);
    }).catch((error) => {
     console.log(error);
  })


}

function votoNormal(rut,votacionId,respuesta,sele) {

  axios.post(`${environment.api}votacion/votoNormal/${rut}/${votacionId}/${respuesta}/${sele}`).then((response) => {
    
  console.log(response);
  }).catch((error) => {
   console.log(error);
})

}


function votoBlanco(rut,votacionId) {

  axios.post(`${environment.api}votacion/votoBlancos/${rut}/${votacionId}`).then((response) => {
    
  console.log(response);
  }).catch((error) => {
   console.log(error)
})

}





  function methodName(item,i) {
   

    item.seleccionada= !item.seleccionada;



    setImagen(  <img  
      src={marcada}
     
      />)
    escogidas();
    
  }

  function votar(){

    let respuestas = 0;

    sele=[];

    console.log('sele',sele);

          for (let index2 = 0; index2 < opcion.length; index2++) {
    
    
    
              if (opcion[index2].seleccionada === true) {
    
                sele.push(opcion[index2].id);
                respuestas++;
              }
              else{
                sele.splice(opcion[index2].id);
              }
    
            }
    
     //   console.log('respuestas', respuestas);
        console.log('opciones esoc¿gidas buenas ',  sele);
    
        if(clave==''){
          Swal.fire('Estimado/a', 'La clave no puede ir vacia!', 'info');
        }else{
    
    
    
    
          if(clave===user.secret){
    
    
            if (respuestas == 0) {
    
              Swal.fire({
                title: 'Atención, Votación en Blanco',
                text: 'No ha seleccionado ninguna opción, por tanto su voto sera considerado como en BLANCO, ¿Esta seguro de emitir el Voto?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Votar',
                confirmButtonColor: 'green',
                cancelButtonColor: 'red',
                cancelButtonText: 'Cancelar',
              }).then((result) => {
                if (result.value) {
                  setTipoVoto('BLANCO');
    
                   //   console.log(this.tipovoto);
                     votoBlanco(rut,Number(votacionId));
    
                     navigate('/*****---',{ state: { id: rut, color: 'green' } });
    
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                  Swal.fire('Cancelado', 'Vuelva a seleccionar', 'info');
                }
              });
    
    
    
    
          }
    
    
          if (respuestas > votacion.maximoOpciones) {
    
    
    
            Swal.fire({
              title: 'Atención, Votación Nula',
              text: 'Ha elegido mas de la cantidad máxima de preferencias, ¿Esta seguro de emitir el Voto?',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: 'Votar',
              confirmButtonColor: 'green',
              cancelButtonColor: 'red',
              cancelButtonText: 'Cancelar',
            }).then((result) => {
              if (result.value) {
                setTipoVoto('NULO');
                votoNulo(rut,Number(votacionId));
    
    
                navigate('/*****---',{ state: { id: rut, color: 'green' } });
    
              } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire('Cancelado', 'Vuelva a seleccionar', 'info');
              }
            });
    
    
          }
    
      if(respuestas>0 && (votacion.maximoOpciones == respuestas  || votacion.maximoOpciones>respuestas) ){
       // console.log('estoy aca');
    
          Swal.fire({
            title: '¿Esta seguro de emitir el Voto?',
            text: 'Una vez emitido no se puede volver a votar.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Votar',
            confirmButtonColor: 'green',
            cancelButtonColor: 'red',
            cancelButtonText: 'Cancelar',
          }).then((result) => {
            if (result.value) {
              setTipoVoto('NORMAL');
    
             
              votoNormal(rut,Number(votacionId),respuestas, sele);
           
    
             navigate('/*****---',{ state: { id: rut, color: 'green' } });
    
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              Swal.fire('Cancelado', 'Vuelva a seleccionar', 'info');
            }
          });
    
        }
    
          }else{
    
            Swal.fire('Estimado/a', 'La clave que esta ingresando no su clave de votación!', 'info');
    
    
    
        }
    
      }
    

  }





 


 
  

  return (
    <div style={{
      textAlign: "center",
      
    }}>
<h1 class="titulo"><strong> {votacion.nombre} </strong> </h1>
<img style={{width: '200px'}}  src={logo} alt="" /> 
    <br/>
<br/>
<div style={{
      textAlign: "center",
      backgroundColor: "#33FF9F",
      margin:"5px",
      borderRadius:"15px",
    }} className={classes.root}>{`Opciones escogidas ${eleccion == null ? "0" : eleccion}  de un máximo de ${votacion.maximoOpciones} ` }</div>

<br/>
<strong><h2><strong>  {categoria.texto} </strong></h2></strong>

<Card style={{
     border: "3px solid #87CEEB",
      margin: "30px",
      borderRadius: "15px",
    }} className={classes.root}>

{opcion.map((item,i) => 
   <CardContent style={{
    cursor: "pointer"
    }}  >

<div style={{
     width: "100%",
     display: "flex",
     flexDirection: "row",
     justifyContent: "center",
     opacity: "unset",
    }} >

<div onClick = {() =>


{ methodName(item,i);

  console.log(item.seleccionada[i]);

} }   

>



{ item.seleccionada ? (
            <img key={i}  
            src={marcada}
           
            />
          

          ) : (
      
            <img   key={i}  
            src={marcar}
           
            />    
                    )}

                    

</div>      
          <div style={{
     width: "100px",
     opacity: "unset",
    }}>
<strong> <h4  style={{
     color: "#393969",
     margin: "15px",
    }} 
    
    dangerouslySetInnerHTML={{ __html: item.texto }}
    ></h4></strong>

            </div>

</div>   
      </CardContent>
  )}
    
     
    </Card>

    <TextField     type="text"     id="clave" onChange={e => setClave(e.target.value)}   value={clave} inputProps={{ maxLength: 9 }}  placeholder="clave de votación" required  />
<br/>

<br/>

<Button style={{maxWidth: '400px', maxHeight: '400px', minWidth: '300px', minHeight: '30px',backgroundColor:'#8db600'}} variant="contained" color="secondary" disableElevation
               onClick={() => {
                votar();
                  
              }}
              >
  Votar
</Button>

<br/>
<br/>
<Button style={{maxWidth: '400px', maxHeight: '400px', minWidth: '300px', minHeight: '30px',backgroundColor:'red'}} variant="contained" color="secondary"
               onClick={() => {
                votarNulo();

              }}
              >
  Anular
</Button>
    </div>

    
  )
}
